import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/pages/login/login.service';
import { Router, NavigationEnd } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OrderTrackingDetailsComponent } from 'src/app/pages/shared/order-tracking-details/order-tracking-details.component';
import { OrderService } from 'src/app/pages/orders/order-dashboard/order.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  userName = '';
  roleName = '';
  image = '';
  name = '';
  ScreenName: any;
  sessionDetails: any;
  UserDetails: any;
 // searchKeyword = '';
  searchBy = 'ASN_No'
  searchKeyword: string = '';
  Code = '';
  flag = 0;

  constructor(
    public alertService: AlertService,private router: Router,
    public dialog: MatDialog, private _loginService: LoginService,
    private _OrderService: OrderService,
  ) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    console.log(this.sessionDetails);
    if (this.sessionDetails != null && this.sessionDetails != undefined && this.sessionDetails != '') {
      this.userName = this.sessionDetails['FirstName'];
      this.roleName = this.sessionDetails['Role'];
      this.Code = this.sessionDetails['Code'];
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.clearSearchKeyword();
      }
    });
    
  }

  ngOnInit() {
    // this.customerDetails();
  }

  // customerDetails(): void {
  //   this._loginService.getuserdetails().subscribe((res) => {
  //     if (res != null) {
  //       //console.log(res);
  //       this.UserDetails = res['Data'];
  //       this.image = res['Data']['ProfilePicUrl'];
  //       this.name = this.UserDetails['UserName'];
  //     }
  //   });
  // }
  logoutUser() {
    localStorage.clear()
    localStorage.removeItem('tabscount');
    this.alertService.createAlert("Logged out successfully", 1);
  }
  public openUserDialogs(id) {
    let dialogRef = this.dialog.open(UserProfileComponent, {
      data: id,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
    });
  }

  public changePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: null,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
    });
  }

  public EditProfile() {
    let dialogRef = this.dialog.open(UserProfileComponent, {
      data: null,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
    });
  }
  onInputBlur(event: FocusEvent) {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (!relatedTarget || !relatedTarget.classList.contains('btn')) {
      this.clearSearchKeyword();
    }
  }
  clearSearchKeyword() {
    this.searchKeyword = ''; // Clear the searchKeyword variable
  }
  
  public search() {
    this.flag += 1;
    if (this.searchKeyword == null || this.searchKeyword === '' || this.searchKeyword === undefined) {
        this.alertService.createAlert("ASN Number is required", 0);
    } else if (this.searchBy === 'ASN_No') {
        const keyword = this.searchKeyword.replace(/\s/g, '');
        console.log('Search keyword:', keyword); // Log the keyword
        this._OrderService.LCLOrderDetails(keyword).subscribe((res) => {
            console.log('Response:', res); // Log the response
            if (res['Success'] === true) {
              if(this.roleName != 'Consignor' || (this.roleName == 'Consignor' && this.Code === res['Data'][0].vc_vendor_code)){
                const data = { res: res, keyword: keyword };
                console.log('Data to pass to dialog:', data); // Log the data object
                if(this.flag > 0){
                  this.dialog.closeAll();
                }
                const dialogRef = this.dialog.open(OrderTrackingDetailsComponent, {
                    data: data,
                    height: 'auto',
                    width: '1100px',
                    autoFocus: false
                });
              }else{
                this.alertService.createAlert("No Data Found", 0);
              }
            } else {
                this.alertService.createAlert("No Data Found", 0);
            }
        });
    }
}

}