<div class="order-tracking-container">
    <header class="order-header">
        <h1 class="order-title">Track Your Consignment ({{ ASN_No }})</h1>
    </header>
    
    <div class="order-details">
        <table class="order-table">
            <thead>
                <tr>
                    <th class="table-header">ASN NO / XPCN</th>
                    <th class="table-header">Booking ID</th>
                    <th class="table-header">ASN Date</th>
                    <th class="table-header">Vendor Code</th>
                    <th class="table-header">DC Site Code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ASN_No}} / <span class="highlight-link" (click)="openLCLXPCNPreview(XPCNID)">{{XPCN}}</span></td>
                    <td>{{vc_booking_id}}</td>
                    <td>{{ BookingDate | date : "dd-MM-y" }}</td>
                    <td>{{vc_vendor_code}}</td>
                    <td>{{vc_dc_code}}</td>
                </tr>
            </tbody>
        </table>
        
        <!-- Next Section for Consignor and Consignee -->
        <table class="order-table">
            <thead>
                <tr>
                    <th class="table-header">Consignor Name</th>
                    <th class="table-header">Consignee Name</th>
                    <th class="table-header">From</th>
                    <th class="table-header">To</th>
                    <th class="table-header">No. Of Boxes</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ConsignorName}}</td>
                    <td>{{ConsigneeName}}</td>
                    <td>{{Origin}}</td>
                    <td>{{Destination}}</td>
                    <td>{{NoOfPackages}}</td>
                </tr>
            </tbody>
        </table>

        <!-- Last Section for Charged Weight, RIL Business, and Status -->
        <table class="order-table">
            <thead>
                <tr>
                    <th class="table-header">Charged Weight</th>
                    <th class="table-header">RIL Business</th>
                    <th class="table-header">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{dec_charged_weight}}</td>
                    <td>{{vc_material_type}}</td>
                    <td>{{Status}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="action-buttons">
        <button class="btn btn-redirect" *ngIf="Redirect" (click)="openpage()">Redirect to Page</button>
        <button class="btn btn-cancel" (click)="close()">Cancel</button>
    </div>
</div>
