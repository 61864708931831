import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoginService } from '../login/login.service';
import { PagesServiceService } from '../pages-service.service';
import { MenuService } from 'src/app/theme/components/menu/menu.service';

@Component({
  selector: 'app-cancelled-asn',
  templateUrl: './cancelled-asn.component.html',
  styleUrls: ['./cancelled-asn.component.scss']
})
export class CancelledAsnComponent implements OnInit {
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  xpcnDetails: any[] = []
  isXPCNAdded: boolean = false
  canXPCNAdded: boolean = true
  filterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList = [];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter = 'YTD';
  user;

  filterToggle: boolean = true;
  CustomDate: boolean = false;

  public popoverStatusTitle: string = "Confirm Generate LR Number";
  public popoverStatusMessage: string =
    "Are you sure you want to Generate a new LR Number?";
  constructor(private fb: FormBuilder,
    private _pageService: PagesServiceService,
    private _datePipe: DatePipe,
    private dialog: MatDialog,
    public alertService: AlertService,
    public _downloadExcelService: DownloadExcelService,
    // private _menuservice :MenuService,
    private _loginService :LoginService
    ) {
    this.user = JSON.parse(localStorage.getItem('userSession'));
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      OrderByDirection: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      DateType: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit() {
    // this.filterForm.controls["DateType"].setValue('YTD');
    
    // this.getCounts()
    this.dateFilterChanged('YTD');

  }

  tabscount: any
  getCounts() {
    this._loginService.GetCountsOfTabs().subscribe(data => {
      if (data != null) {
        this.tabscount = data['Data'];
        if (this.tabscount.length > 0) {
          localStorage.setItem('tabscount', JSON.stringify(data['Data']));

          // this._menuservice.getVerticalMenuItems();
        }
      }
    })
  }

  getASnList() {
    this._pageService.GetCancelledAsnList(this.filterForm.value).subscribe((res) => {
      // console.log(res["Data"]);
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
      }
    });
  }

  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['FilterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'update' && this.filterBy != 'cdate') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      } else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getASnList();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['FilterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls['OrderBy'].setValue(null);
    this.filterForm.controls['OrderByDirection'].setValue(null);
    this.filterForm.controls['KeywordDate'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.getASnList();
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getASnList();
  }

  fromDate
  toDate

  dateFilterChanged(type) {

    this.filterForm.controls["DateType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["DateType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getASnList();

  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["DateType"].setValue(null);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getASnList();
  }

  excelData: any[];

  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.GetCancelledAsnList(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'ASN No': element['ASN_Num'],
              'ASN Date': this._datePipe.transform(element['BookingDate'], 'dd-MM-y, (HH:mm)'),
              'XP Booking Id': element['BookingIdCode'],
              'Vendor Code': element['VendorCode'],
              'Vendor Name': element['VendorName'],
              'Vendor Location': element['VendorLoc'],
              'No Of Packages': element['NoOfBoxes'],
              'DC Site Code': element['DcCode'],
              'DC Site Location': element['DcLoc'],
              'Upload Date': this._datePipe.transform(element['CreatedDate'], 'dd-MM-y, (HH:mm)'),
              'RIL Business': element['BusinessType'],
              'Delivery Type': element['DeliveryType'],
              'Remarks': element['CancelledReason'],
              'Cancelled Date': element['CancelledDate']
            })
          });
        }
        else {
          this.excelData.push({
            'ASN No': '-',
            'ASN Date': '-',
            'XP Booking Id': '-',
            'Vendor Code': '-',
            'Vendor Name': '-',
            'Vendor Location': '-',
            'No Of Packages': '-',
            'DC Site Code': '-',
            'DC Site Location': '-',
            'Upload Date': '-',
            'Remarks': '-',
            'Cancelled Date': '-'
          })
        }

        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Cancelled ASN List');
      }
    })
  }
  getTooltipContent(i){
 
    const cancelled = i.cancelled_by;


  // Construct the tooltip content using the properties
  const tooltipContent = `
    Cancelled By : ${cancelled}\n
  `;

  return tooltipContent;
  }
}

