<div class="row">
    <div class="col-sm-9">
        <div class="row">
            <div class="col-sm-6">
                <input [(ngModel)]="searchKeyword" (blur)="onInputBlur($event)" (keydown.enter)="search()" class="form-control" style="margin-left: -17px; border-color: black; border-radius: 0.25rem;" placeholder="Enter ASN No." title="Enter XPCN No">
            </div>
            <div class="col-sm-6">
                <button class="btn m-1" style="background-color: #3E50B4; color:#fff" (click)="search()">Search</button>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true"
        style="color: #3E50B4 !important; cursor: pointer !important; float:right;margin-top: 7px ;"
        aria-expanded="false"> <b style="margin-right: 10px;">{{userName}}</b>
        <i class="fa fa-user-o" aria-hidden="true"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="user-menu">
        <div class="user-menu">
            
            <a class="dropdown-item" (click)="EditProfile()" style="cursor: pointer !important;"><i
                    class="fa fa-user mr-2"></i>Profile</a>
            <a class="dropdown-item" (click)="changePassword()" style="cursor: pointer !important;"><i
                    class="fa fa-key mr-2"></i>Change Password</a>
            <a class="dropdown-item mb-1" routerLink="/login" (click)="logoutUser()"><i
                    class="fa fa-power-off mr-2"></i>Log out</a>
        </div>
    </div>
    </div>
</div>